import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ExportOperation } from '../models/export-operation.model';
import pick from 'lodash-es/pick';
import { ParamsService } from './params.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportOperationService {
  readonly permitted_attributes = [
    'cols',
    'items',
    'export_type',
    'client_id',
    'for_client_documents',
    'pdf_font',
    'pdf_cover',
    'pdf_size',
    'pdf_layout',
    'pdf_cover_data',
    'pdf_with_remarks',
    'include_risks'
  ];

  constructor(private http: HttpClient,
              private paramsService: ParamsService) { }


  get(id: number): any {
    return this.http.get<ExportOperation>(`/export_operations/${id}`);
  }

  preview(record: ExportOperation): Observable<Blob> {
    const export_operation = new ExportOperation().fromJson(pick(record, this.permitted_attributes));
    return this.http.post<Blob>('/export_operations/preview', { export_operation }, { responseType: 'blob' as 'json' });
  }

  save(record: ExportOperation): any {
    const export_operation = new ExportOperation().fromJson(pick(record, this.permitted_attributes));
    return this.http.post('/export_operations', { export_operation });
  }

}
