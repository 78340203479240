import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { Client } from '@app/models/client.model';
import { Contract } from '@app/models/contract.model';
import { Damage } from '@app/models/damage.model';
import { Product } from '@app/models/product.model';
import { User } from '@app/models/user.model';
import { Bookmark } from '@app/models/bookmark.model';
import { FormComponent } from '../form/form.component';
import { BookmarkService } from '@app/services/bookmark.service';

@Component({
  selector: 'app-bookmarks-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.scss']
})
export class ShowComponent implements OnInit {

  @Input() bookmarkable: Client | Contract | Damage | Product;
  @Input() user: User;
  @Input() bookmark: Bookmark;
  @Output() afterAction = new EventEmitter<Bookmark>();

  constructor(public translate: TranslateService,
              private service: BookmarkService,
              private dialog: MatDialog) { }

  ngOnInit() {
  }

  openForm() {
    let bookmark = this.bookmark;
    if (!bookmark || !bookmark.id) {
      bookmark = new Bookmark();
      bookmark.user_id = this.user.id;
      if (this.bookmarkable instanceof Client) {
        bookmark.bookmarkable_type = 'Client';
      } else if (this.bookmarkable instanceof Contract) {
        bookmark.bookmarkable_type = 'Contract';
      } else if (this.bookmarkable instanceof Damage) {
        bookmark.bookmarkable_type = 'Damage';
      } else if (this.bookmarkable instanceof Product) {
        bookmark.bookmarkable_type = 'Product';
      }
      bookmark.bookmarkable_id = this.bookmarkable.id;
    }

    this.dialog.open(FormComponent, {
      autoFocus: false,
      disableClose: false,
      width: '400px',
      panelClass: 'mat-dialog-mobile-fullscreen',
      data: { bookmark }
    }).afterClosed().subscribe(result => {
      if (result && result.id) {
        if (this.bookmark && this.bookmark.id) {
          Object.keys(result).map(key => this.bookmark[key] = result[key]);
        } else {
          this.bookmark = new Bookmark().fromJson(result);
        }
        this.afterAction.emit(this.bookmark);
      } else if (result && !result.id) {
        this.bookmark = null;
        this.afterAction.emit(null);
      }
    });
  }

}
