<form (ngSubmit)="onFormSubmit()" novalidate [formGroup]="form" class="mat-dialog-sticky-wrapper" style="padding: 20px;">
  <div>
    <h2 mat-dialog-title fxLayout="row">
      <div fxLayoutAlign="center center" fxFlex>
        <span style="font-size: 24px; font-weight: bold;">{{ 'chatGpts.header' | translate }}</span> <mat-icon style="color: #ffc800; margin-left: 10px;">auto_awesome</mat-icon>
      </div>
      <div fxFlex="50px">
        <mat-icon class='right' (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
        <mat-icon class='right' (click)="resetConversations()" style="cursor: pointer;" [matTooltip]="'chatGpts.resetConversations' | translate" *ngIf="records.length >= 2">refresh</mat-icon>
      </div>
    </h2>
    <mat-dialog-content class="mat-subtitle-2">
      <br/>
      <ng-container *ngIf="currentUser?.with_chatgpt">
        <div class="msg-history" *ngIf="records.length > 0" #formScrollTo>
          <div fxLayout="row" *ngFor="let record of records" [fxLayoutAlign]="record.type === 'request' ? 'end' : 'start'">
            <mat-card appearance="outlined" class="message-card" fxFlex="70%" [ngClass]="{'stream-chat-other' : record.type !== 'request', 'stream-chat-own' : record.type === 'request'}">
              <mat-card-content class="message-card-content">
                <div [innerHTML]="record.text | safe: 'html'" class="text-style" *ngIf="record.text"></div>
                <a mat-icon-button (click)="copyToClipboard(record)" [matTooltip]="'copyUrlToClipboard.tooltip' | translate" style="position: absolute; bottom: 0px; right: 0px;" *ngIf="record.type === 'response' && record.text && !record.job">
                  <mat-icon>content_copy</mat-icon>
                </a>
                <p *ngIf="record.type === 'response' && !record.text && !record.job">
                  {{ 'chatGpts.errorTxt' | translate }}
                </p>
                <div class="chat-bubble" *ngIf="record.type === 'response' && !record.text && record.job">
                  <div class="typing">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <ng-container *ngIf="records.length === 0">
          <div class="assist-begin-txt">
            <p>{{ 'chatGpts.beginHeader' | translate }}</p>
            <div [innerHTML]="'chatGpts.activateAssistThree' | translate | safe: 'html'"></div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!currentUser?.with_chatgpt">
        <div class="assist-begin-txt">
          {{ 'chatGpts.activateAssistOne' | translate }}
          <br/>
          <br/>
          <div [innerHTML]="'chatGpts.activateAssistTwo' | translate | safe: 'html'"></div>
          <div [innerHTML]="'chatGpts.activateAssistThree' | translate | safe: 'html'"></div>
        </div>
      </ng-container>
      <br/>
      <br/>
    </mat-dialog-content>
  </div>
  <div fxLayout="row" class="assist-form-data" fxLayoutGap="10px" *ngIf="currentUser?.with_chatgpt">
    <div fxFlex>
      <mat-form-field fxFlex appearance="none">
        <mat-label>{{ 'chatGpts.promptPlaceholder' | translate }}</mat-label>
        <textarea matInput formControlName="prompt" rows="5"></textarea>
      </mat-form-field>
    </div>
    <div fxFlex="40px" fxLayoutAlign="center end" style="position: relative; top: 11px;">
      <button mat-icon-button [disabled]='submitted' type="submit">
        <mat-icon *ngIf="!submitted">send</mat-icon>
        <mat-spinner mode="indeterminate" diameter="20" *ngIf="submitted"></mat-spinner>
      </button>
    </div>
  </div>
</form>

