<ng-container *ngIf="user && user.role !== 'client' && bookmarkable">
  <div (click)="openForm()" *ngIf="bookmark && bookmark.id" class="bookmark-btn" fxLayout="column" [matTooltip]="bookmark.description">
    <mat-icon *ngIf="!bookmark.description">bookmark</mat-icon>
    <mat-icon *ngIf="bookmark.description">bookmarks</mat-icon>
    <div *ngIf="bookmark.bookmarkable_date" class="bookmark-btn-info">
      {{ bookmark.bookmarkableDate.format('DD.MM.YY') }}
    </div>
    <div *ngIf="!bookmark.bookmarkable_date" class="bookmark-btn-info">
      &nbsp;
    </div>
  </div>
  <div (click)="openForm()" *ngIf="!bookmark || !bookmark.id" class="bookmark-btn">
    <mat-icon style="color: #808080;">bookmark_border</mat-icon>
  </div>
</ng-container>
