<div  style="padding-left: 20px; padding-right: 20px; margin-top: 20px;" fxLayout="row" *ngIf="!loading && users.length > 0">
  <mat-form-field fxFlex>
    <mat-label>{{ 'bookmarks.selectOtherUser' | translate }}</mat-label>
    <mat-select [(ngModel)]="selectedUserId" (ngModelChange)="loadRecords()">
      <mat-option *ngFor="let user of users" [value]="user.id">
        <div fxLayout="row" style="align-items: center;">
          <img [src]="user.profilePicture | safe: 'url'" alt="profile picture" [matTooltip]="user.name" class="user-profile-picture-small">
          {{ user.reverseName }}
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<mat-list style="padding-left: 5px; padding-right: 5px;" *ngIf="!loading && bookmarks.length > 0">
  <h4 class="mat-subheader" *ngIf="groupedBookmarks.clients.length > 0">{{ 'clients.header' | translate }}</h4>
  <mat-list-item style="cursor: pointer;" *ngFor="let bookmark of groupedBookmarks.clients">
    <app-bookmarks-show [bookmarkable]="bookmarkable(bookmark)" [user]="user" [bookmark]="bookmark" matListItemIcon (afterAction)="reloadRecord($event)"></app-bookmarks-show>
    <div matListItemTitle (click)="navigateToBookmarkable(bookmark)">
      <mat-icon class="icon-18" style="position: relative; top: 3px;">{{ bookmark.bookmarkable_icon }}</mat-icon>
      {{ bookmark.bookmarkable_name }}
    </div>
    <div matListItemLine (click)="navigateToBookmarkable(bookmark)">
      {{ bookmark.description }}
    </div>
  </mat-list-item>

  <h4 class="mat-subheader" *ngIf="groupedBookmarks.contracts.length > 0">{{ 'contracts.header' | translate }}</h4>
  <mat-list-item style="cursor: pointer;" *ngFor="let bookmark of groupedBookmarks.contracts">
    <app-bookmarks-show [bookmarkable]="bookmarkable(bookmark)" [user]="user" [bookmark]="bookmark" matListItemIcon (afterAction)="reloadRecord($event)"></app-bookmarks-show>
    <div matListItemTitle (click)="navigateToBookmarkable(bookmark)">
      <mat-icon class="icon-18" style="position: relative; top: 3px;">{{ bookmark.bookmarkable_icon }}</mat-icon>
      {{ bookmark.bookmarkable_name }}
    </div>
    <div matListItemLine (click)="navigateToBookmarkable(bookmark)">
      {{ bookmark.description }}
    </div>
  </mat-list-item>

  <h4 class="mat-subheader" *ngIf="groupedBookmarks.damages.length > 0">{{ 'damages.header' | translate }}</h4>
  <mat-list-item style="cursor: pointer;" *ngFor="let bookmark of groupedBookmarks.damages">
    <app-bookmarks-show [bookmarkable]="bookmarkable(bookmark)" [user]="user" [bookmark]="bookmark" matListItemIcon (afterAction)="reloadRecord($event)"></app-bookmarks-show>
    <div matListItemTitle (click)="navigateToBookmarkable(bookmark)">
      <mat-icon class="icon-18" style="position: relative; top: 3px;">{{ bookmark.bookmarkable_icon }}</mat-icon>
      {{ bookmark.bookmarkable_name }}
    </div>
    <div matListItemLine (click)="navigateToBookmarkable(bookmark)">
      {{ bookmark.description }}
    </div>
  </mat-list-item>

  <h4 class="mat-subheader" *ngIf="groupedBookmarks.products.length > 0">{{ 'products.header' | translate }}</h4>
  <mat-list-item style="cursor: pointer;" *ngFor="let bookmark of groupedBookmarks.products">
    <app-bookmarks-show [bookmarkable]="bookmarkable(bookmark)" [user]="user" [bookmark]="bookmark" matListItemIcon (afterAction)="reloadRecord($event)"></app-bookmarks-show>
    <div matListItemTitle (click)="navigateToBookmarkable(bookmark)">
      <mat-icon class="icon-18" style="position: relative; top: 3px;">{{ bookmark.bookmarkable_icon }}</mat-icon>
      {{ bookmark.bookmarkable_name }}
    </div>
    <div matListItemLine (click)="navigateToBookmarkable(bookmark)">
      {{ bookmark.description }}
    </div>
  </mat-list-item>
</mat-list>

<mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>

<div class="no-items-container" *ngIf="!loading && bookmarks.length === 0">
  {{ 'list_is_empty' | translate }}
</div>
