import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ShowComponent } from './show/show.component';
import { FormComponent } from './form/form.component';
import { SharedModule } from '@app/modules/shared/shared.module';
import { IndexComponent } from './index/index.component';

@NgModule({
    declarations: [
        ShowComponent,
        FormComponent,
        IndexComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        ShowComponent,
        IndexComponent
    ]
})
export class BookmarksModule { }
