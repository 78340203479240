<form (ngSubmit)="onFormSubmit()" novalidate [formGroup]="form" class="mat-dialog-sticky-wrapper">
  <div>
    <h2 mat-dialog-title>
      {{ 'bookmarks.dialogHeader' | translate }}
      <mat-icon class='right' (click)="closeDialog()" style="cursor: pointer;">close</mat-icon>
    </h2>
    <mat-divider></mat-divider>
    <mat-dialog-content *ngIf="bookmark" class="mat-subtitle-2">
      <br/>
      <p>{{ 'bookmarks.helpTxt' | translate }}</p>

      <!-- bookmarkable_date -->
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <mat-label>{{ 'bookmarks.bookmarkable_date' | translate }}</mat-label>
          <input matInput formControlName="bookmarkable_date"  [matDatepicker]="datePicker" autocomplete="new-password">
          <mat-datepicker-toggle matIconSuffix [for]="datePicker" (click)="datePicker.open()"></mat-datepicker-toggle>
          <mat-datepicker touchUi #datePicker [calendarHeaderComponent]="calendarHeaderComponent"></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- description -->
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <mat-label>{{ 'bookmarks.description' | translate }}</mat-label>
          <textarea
            matInput
            formControlName="description"
            rows="5">
          </textarea>
        </mat-form-field>
      </div>
    </mat-dialog-content>
  </div>

  <div>
    <mat-dialog-actions fxLayout="row">
      <a mat-raised-button color="accent" (click)="deleteRecord()" *ngIf="bookmark && bookmark.id">
        {{ 'bookmarks.removeBtn' | translate }}
      </a>
      <div style="flex: 1 0 auto;"></div>
      <button mat-raised-button color="primary" type="submit" [disabled]='submitted'>
        {{ 'bookmarks.saveBtn' | translate }}
      </button>
    </mat-dialog-actions>
  </div>
</form>
