import { Component, Inject, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, Subject, of, timer } from 'rxjs';
import { switchMap, debounceTime, startWith, filter, takeUntil, skip, map, tap, distinctUntilChanged } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActionCableService, Channel } from 'angular2-actioncable';

import { User } from '@app/models/user.model';
import { AuthService } from '@app/services/auth.service';

import { ChatGpt } from '@app/models/chat-gpt.model';
import { ChatGptService } from '@app/services/chat-gpt.service';
import { ValidationService } from '@app/services/validation.service';
import { Analytics } from '@app/helpers/analytics';

@Component({
  selector: 'app-chat-gpt-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    ValidationService,
    ActionCableService
  ]
})
export class FormComponent implements OnInit, OnDestroy {
  records: ChatGpt[] = [];
  form: UntypedFormGroup;
  submitted = false;
  currentUser: User;

  protected destroy$ = new Subject<void>();
  @ViewChild('formScrollTo', { static: false }) formScrollTo: ElementRef<HTMLElement>;

  channel: Channel;
  watcherChannel: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              public validationService: ValidationService,
              public translate: TranslateService,
              private service: ChatGptService,
              private dialog: MatDialog,
              private authService: AuthService,
              private snackBar: MatSnackBar,
              private cableService: ActionCableService,
              public dialogRef: MatDialogRef<FormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.currentUser = new User().fromJson(JSON.parse(this.authService.currentUser));

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.closeDialog();
      }
    });
    if (!this.channel) {
      this.channel = this.cableService.cable(this.currentUser.actionCable(this.authService.token)).channel('ChatGptChannel');
      if (this.channel) {
        this.watcherChannel = this.channel.received().subscribe(message => {
          if (message.message && message.message !== 'Insurgo chatgpt message complete') {
            const rec = this.records.find(e => e.job === message.job);
            if (rec) {
              rec.text = `${rec.text}${message.message.replace("\\n\\n", "<br/><br/>").replace("\\n", "<br/>")}`;
              if (rec.text.slice(0, 1) === "\n") {
                rec.text = rec.text.slice(1);
              }
              if (rec.text.slice(0, 1) === "\n") {
                rec.text = rec.text.slice(1);
              }
              if (rec.text.slice(0, 1) === " ") {
                rec.text = rec.text.slice(1);
              }
            }
            this.scrolleChat();
          } else if (message.message && message.message === 'Insurgo chatgpt message complete') {
            const rec = this.records.find(e => e.job === message.job);
            if (rec) {
              rec.job = null;
              this.submitted = false;
            }
          }
        });
      }
    }
    this.buildForm();
  }

  ngOnDestroy() {
    if (this.watcherChannel) {
      this.watcherChannel.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  buildForm() {
    this.form = this.formBuilder.group({
      prompt: ['', Validators.required]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFormSubmit() {
    if (this.form.valid && this.currentUser.with_chatgpt) {
      this.submitted = true;
      const promptTxt = this.form.get('prompt').value.toString().trim();
      if (this.wordsFromText(promptTxt).length >= 150) {
        this.snackBar.open(this.translate.instant('chatGpts.requestLengthError'), 'x', {
          duration: 4000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'style-error'
        });
        return;
      }
      this.form.get('prompt').disable();

      const messages = [];

      if (this.records.length > 0) {
        this.records.forEach((record: ChatGpt) => {
          messages.push({
            role: (record.type === 'response' ? 'assistant' : 'user'),
            content: record.text
          });
        });
      }
      messages.push({
        role: 'user',
        content: promptTxt
      });

      if (messages.length >= 25) {
        this.snackBar.open(this.translate.instant('chatGpts.requestConversationsLengthError'), 'x', {
          duration: 4000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'style-error'
        });
        return;
      }

      this.service.completions(messages).subscribe(
        res => {
          const reqRecord = new ChatGpt();
          reqRecord.type = 'request';
          reqRecord.text = promptTxt;
          this.records.push(reqRecord);

          const respRecord = new ChatGpt().fromJson(res);
          respRecord.type = 'response';
          respRecord.text = '';
          this.records.push(respRecord);

          this.form.get('prompt').enable();

          this.buildForm();

          this.scrolleChat();
        },
        error => {
          this.submitted = false;
          if (error.status === 422) {
            this.validationService.updateFormErrors(this.form, error.error.errors);
          }
        }
      );

      Analytics.track(this.currentUser, 'Assist', 'allgemeinen Assist gefragt');
    }
  }

  scrolleChat() {
    const source = timer(100);
    source.subscribe(val => {
      if (this.formScrollTo) {
        this.formScrollTo.nativeElement.scrollTop = this.formScrollTo.nativeElement.scrollHeight;
      }
    });
  }

  resetConversations() {
    if (!this.submitted) {
      this.records = [];
    }
  }

  wordsFromText(text: string) {
    const res = [];
    const str = text.replace(/[\t\n\r\.\?\!]/gm, " ").split(" ");
    str.map((s) => {
      let trimStr = s.trim();
      if (trimStr.length > 0) {
        res.push(trimStr);
      }
    });
    return res;
  }

  copyToClipboard(record: ChatGpt) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = record.text;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.snackBar.open(this.translate.instant('copyUrlToClipboard.success'), 'x', {
      duration: 4000, verticalPosition: 'top', horizontalPosition: 'center', panelClass: 'style-success'
    });
  }
}
