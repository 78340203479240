import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { User } from '@app/models/user.model';
import { Client } from '@app/models/client.model';
import { Contract } from '@app/models/contract.model';
import { Damage } from '@app/models/damage.model';
import { Product } from '@app/models/product.model';
import { Bookmark } from '@app/models/bookmark.model';
import { FormComponent } from '../form/form.component';
import { BookmarkService } from '@app/services/bookmark.service';
import { UserService } from '@app/services/user.service';
import { Analytics } from '@app/helpers/analytics';

@Component({
  selector: 'app-bookmarks-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  @Input() user: User;
  bookmarks: Bookmark[] = [];
  groupedBookmarks = {
    clients: [],
    contracts: [],
    damages: [],
    products: []
  };
  users: User[] = [];
  loading = false;
  selectedUserId = null;

  constructor(public translate: TranslateService,
              private service: BookmarkService,
              private userService: UserService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit() {
    this.selectedUserId = this.user.id;
    if (this.user.role !== 'external') {
      this.loadUsers();
    }
    this.loadRecords();
  }

  loadUsers() {
    this.userService.index(0, { status: 'confirmed', roles: ['manager', 'employee', 'external'] }).subscribe(
      (response) => {
        this.users = response.body.map(u => new User().fromJson(u));
      }
    );
  }

  loadRecords() {
    this.loading = true;
    this.bookmarks = [];
    this.groupedBookmarks = {
      clients: [],
      contracts: [],
      damages: [],
      products: []
    };
    this.service.index(this.selectedUserId).subscribe(
      (response) => {
        this.bookmarks = response.body.map(u => new Bookmark().fromJson(u));
        this.bookmarks.forEach((bookmark: Bookmark) => {
          if (bookmark.bookmarkable_type === 'Client') {
            this.groupedBookmarks.clients.push(bookmark);
          } else if (bookmark.bookmarkable_type === 'Contract') {
            this.groupedBookmarks.contracts.push(bookmark);
          } else if (bookmark.bookmarkable_type === 'Damage') {
            this.groupedBookmarks.damages.push(bookmark);
          } else if (bookmark.bookmarkable_type === 'Product') {
            this.groupedBookmarks.products.push(bookmark);
          }
        });
        this.loading = false;
      }
    );
  }

  reloadRecord(event) {
    if (event === null) {
      this.loadRecords();
    }
  }

  bookmarkable(bookmark: Bookmark) {
    if (bookmark.bookmarkable_type === 'Client') {
      const client = new Client();
      client.id = bookmark.bookmarkable_id;
      return client;
    } else if (bookmark.bookmarkable_type === 'Contract') {
      const contract = new Contract();
      contract.id = bookmark.bookmarkable_id;
      return contract;
    } else if (bookmark.bookmarkable_type === 'Damage') {
      const damage = new Damage();
      damage.id = bookmark.bookmarkable_id;
      return damage;
    } else if (bookmark.bookmarkable_type === 'Product') {
      const product = new Product();
      product.id = bookmark.bookmarkable_id;
      return product;
    }
  }

  navigateToBookmarkable(bookmark: Bookmark) {
    Analytics.track(this.user, 'Markierungen', 'Markierung aufgerufen');
    this.router.navigate([bookmark.bookmarkable_link]);
  }

}
