import { Component, Inject, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Bookmark } from '@app/models/bookmark.model';
import { ValidationService } from '@app/services/validation.service';
import { TranslateService } from '@ngx-translate/core';
import { BookmarkService } from '@app/services/bookmark.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MY_FORMATS } from '@app/models/date-format.model';
import { CalendarHeaderComponent } from '@app/modules/helpers/calendar-header/calendar-header.component';
import { MatDatepicker } from '@angular/material/datepicker';
import { CalendarService } from '@app/services/calendar.service';
import moment from 'moment';
import 'moment/locale/de';
import { Subscription } from 'rxjs';
import { Analytics } from '@app/helpers/analytics';
import { User } from '@app/models/user.model';
import { AuthService } from '@app/services/auth.service';

@Component({
  selector: 'app-bookmarks-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [
    ValidationService,
    CalendarService,
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class FormComponent implements OnInit, OnDestroy{
  currentUser: User;
  bookmark: Bookmark;
  form: UntypedFormGroup;
  submitted = false;

  calendarHeaderComponent = CalendarHeaderComponent;
  @ViewChild('datePicker', { static: false }) datePicker: MatDatepicker<any>;
  datepickerServiceWatcher: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
              public validationService: ValidationService,
              public translate: TranslateService,
              private service: BookmarkService,
              private dateAdapter: DateAdapter<Date>,
              private calendarService: CalendarService,
              private authService: AuthService,
              public dialogRef: MatDialogRef<FormComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.bookmark = data.bookmark;
  }

  ngOnInit() {
    this.currentUser = new User().fromJson(JSON.parse(this.authService.currentUser));
    this.dateAdapter.setLocale(this.translate.currentLang);
    this.buildForm();
    this.datepickerServiceWatcher = this.calendarService.isCloseCalander.subscribe(isClose => {
      if (isClose) {
        if (this.datePicker.opened) {
          this.datePicker.close();
          this.form.get('bookmarkable_date').patchValue(moment());
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.datepickerServiceWatcher) {
      this.datepickerServiceWatcher.unsubscribe();
    }
  }

  buildForm() {
    this.form = this.formBuilder.group({
      bookmarkable_date: [this.bookmark.bookmarkable_date],
      description: [this.bookmark.description]
    });
  }

  afterSave(bookmark: Bookmark) {
    this.dialogRef.close(bookmark);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.submitted = true;
      const updatedRecord: Bookmark = Object.assign({
        id: this.bookmark.id,
        user_id: this.bookmark.user_id,
        bookmarkable_type: this.bookmark.bookmarkable_type,
        bookmarkable_id: this.bookmark.bookmarkable_id
      }, this.form.value);

      this.service.save(updatedRecord).subscribe(
        res => {
          if (updatedRecord.bookmarkable_type === 'Client') {
            Analytics.track(this.currentUser, 'Markierungen', 'Kunde markiert');
          } else if (updatedRecord.bookmarkable_type === 'Contract') {
            Analytics.track(this.currentUser, 'Markierungen', 'Vertrag markiert');
          } else if (updatedRecord.bookmarkable_type === 'Damage') {
            Analytics.track(this.currentUser, 'Markierungen', 'Schaden markiert');
          } else if (updatedRecord.bookmarkable_type === 'Product') {
            Analytics.track(this.currentUser, 'Markierungen', 'Produkt markiert');
          }
          this.submitted = false;
          this.afterSave(new Bookmark().fromJson(res));
        },
        error => {
          this.submitted = false;
          if (error.status === 422) {
            this.validationService.updateFormErrors(this.form, error.error.errors);
          }
        }
      );
    }
  }

  deleteRecord() {
    this.service.delete(this.bookmark.id).subscribe(
      (response) => {
        this.afterSave(new Bookmark());
      }
    );
  }

}
