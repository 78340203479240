import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '@app/modules/shared/shared.module';
import { FormComponent } from './form/form.component';
import { EditorFormComponent } from './editor-form/editor-form.component';

@NgModule({
  declarations: [
    FormComponent,
    EditorFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    FormComponent,
    EditorFormComponent
  ]
})
export class ChatGptModule { }
