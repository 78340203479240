import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoutingState {
  private hist = [];

  constructor(private router: Router) {}

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        if (urlAfterRedirects.includes('?')) {
          this.hist = [...this.hist, urlAfterRedirects.substr(0, urlAfterRedirects.indexOf('?'))];
        } else {
          this.hist = [...this.hist, urlAfterRedirects];
        }
      });
  }

  public get history(): string[] {
    return this.hist;
  }

  public get previousUrl(): string {
    return this.hist[this.hist.length - 2] || '/dashboard';
  }
}
